export { }
import { parse } from 'date-fns';
declare global {
    interface String {
        Mask(type: 'phone' | 'email'): string;
        mobileRaw(): string;
        toDate(dateFormat?: string): Date;
    }
}

String.prototype.Mask = function (type: 'phone' | 'email'): string {
    if (this) {
        if (type == "phone" && this.length > 4) {
            return `(***)***-${this.substring((this.length - 4), (this.length))}`
        } else {
            return `*****${this.substring(5, (this.length))}`
        }
    }
    return this as string;
}

String.prototype.mobileRaw = function (): string {
    if (this) {
        return this.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
    }
    return this as string;
}

String.prototype.toDate = function (dateFormat?: string): Date {
    if (this) {
        if (!dateFormat) dateFormat = "MM/dd/yyyy h:m:s aa";
        return parse(this.toString(), dateFormat, new Date());
    }
    return null;
}