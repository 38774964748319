import { Component, OnInit, inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { finalize, of, switchMap } from 'rxjs'
import { BaseComponent } from '../../../../common/components/base.component'
import { Message } from '../../../../common/enums/message.enum'
import { CollectionType } from '../../../../common/enums/mfa.enum'
import { LoginFlow, UserDetails } from '../../../../common/models/Login'
import { MfaService } from '../../../../common/services/mfa/mfa.service'
import { NotificationService } from '../../../../common/services/notification.service'
import { RouteService } from '../../../../common/services/route.service'
import { SpinnerService } from '../../../../common/services/spinner.service'
import { LoginFlowStateService } from '../../../../common/store/state/login-flow/login-flow.state.service'
import { UserStateService } from '../../../../common/store/state/user/user.state.service'
import { AuthService } from '../../../../common/services/auth.service'
import { SessionTypeEnum } from '../../../../common/models/SessionTypeEnum'

@Component({
  selector: 'app-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrl: './mfa-verification.component.scss'
})
export class MfaVerificationComponent extends BaseComponent implements OnInit {

  header = 'Two-Factor Authentication'
  subheader = ''
  subheader2 = 'Please enter the six digit code sent to your selected method'
  subheader3 = ''
  #spinnerService = inject(SpinnerService)
  #userStateService = inject(UserStateService)
  #loginFlowStateService = inject(LoginFlowStateService)
  #authService = inject(AuthService)

  #activatedRoute = inject(ActivatedRoute)
  #mfaService = inject(MfaService)
  #routeService = inject(RouteService)
  #notificationService = inject(NotificationService)

  #mfaSpinnerId = 'mfa-spinner'
  pinForm: FormGroup
  errorMessage: string
  collectionType: CollectionType
  userInfo: UserDetails
  loginFlow: LoginFlow
  contact: string

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.pinForm = new FormGroup({
      pin: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6,6}')]),
    })

    this.collectionType = this.#activatedRoute.snapshot.params['deviceType']
    this.userInfo = this.#userStateService.getUser()
    this.loginFlow = this.#loginFlowStateService.getLoginFlow()
    this.contact = (this.isEmailVerificationType() ? this.userInfo.ContactEmail : this.userInfo.MobilePhone)
    this.buildHeader()
  }

  onValidate() {
    this.#spinnerService.setLoading(true, this.#mfaSpinnerId)
    const phoneFlow = this.collectionType === CollectionType.email && this.loginFlow.Do2FaSetup
    this.subscriptions.add(
      this.#mfaService.validateContactPin(this.collectionType, this.contact, this.pinForm.value.pin, SessionTypeEnum.MFA)

        .pipe(switchMap(() => {
          return phoneFlow ? of(null) : this.#authService.getWelcomeInfo()
        }), finalize(() => {
          this.#spinnerService.setLoading(false, this.#mfaSpinnerId)
        }))
        .subscribe({
          next: () => {
            if (phoneFlow) {
              this.router.navigate([`/mfa/collection/phone`])
            } else {
              this.router.navigate(['/welcome'])
            }
          },
          error: () => {
            this.errorMessage = 'Please try again'
          }
        })
      )
  }

  sendPin() {
    // this.#spinnerService.setLoading(true, this.#mfaSpinnerId)
    this.subscriptions.add(
      this.#mfaService.validateContact(this.collectionType, this.contact)
        .pipe(finalize(() => {
          // this.#spinnerService.setLoading(false, this.#mfaSpinnerId)
        }))
        .subscribe(
          {
            next: () => {
              this.#notificationService.showSuccess(Message.PinResentSuccess)
            },
            error: () => {
              this.errorMessage = 'Invalid Email or Phone'
            }
          }
        )
      )
  }

  onBackClicked() {
    if (this.loginFlow.Do2FaSetup) {
      this.#routeService.back(`/mfa/collection/${this.collectionType}`)
    } else {
      this.#routeService.back(`/mfa/selection`)
    }
  }

  buildHeader() {
    if (this.isEmailVerificationType()) {
      this.subheader =  this.isCollectionFlow() ? `Email Verification` : ''
      this.subheader2 =  this.isCollectionFlow() ? `Please enter the six digit code sent to your email` : this.subheader2
      this.subheader3 = `at *****${this.contact.substring(5,(this.contact.length))}`
    } else {
      this.subheader =  this.isCollectionFlow() ? `Phone Verification` : ''
      this.subheader2 =  this.isCollectionFlow() ? `Please enter the six digit code sent to your mobile phone` : this.subheader2
      this.subheader3 = `at (***)***-${this.contact.substring(6,(this.contact.length))}`
    }
  }

  isCollectionFlow() {
    return this.loginFlow.Do2FaSetup
  }

  isEmailVerificationType() {
    return this.collectionType === CollectionType.email
  }

  isPhoneVerificationType() {
    return this.collectionType === CollectionType.phone
  }
}
