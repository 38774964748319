<app-authorized-header [customClass]="'flex justify-content-center'" [headerCustomClass]="'text-primary'"
    [header]="'Hi, ' + user?.FirstName + ' '+ user?.LastName"
    [subHeader]="'Let’s make SARA Plus work for you! Let us know if you need any help, our support team is happy to help.'"></app-authorized-header>
<div class="flex justify-content-center">
    <div *ngIf="welcomeInfo && user" class="w-full lg:w-10 xl:w-8 flex gap-3 flex-column">
        <div>
            <div class="grid">
                <div class="col">
                    <p-button icon="pi pi-plus" [size]="'large'" label="START NEW ORDER" styleClass="w-full"
                        [rounded]="true" (onClick)="onStartNewOrderClicked()" *ngIf="orderEntryButtonEnabled" />
                </div>
            </div>
        </div>
        <div *ngIf="welcomeInfo.NewFeatures && welcomeInfo.NewFeatures.length > 0">
            <p-card styleClass="border-1 border-primary border-round-lg shadow-none">
                <ng-template pTemplate="title">
                    <div class="p-card-title text-primary"> New Features</div>
                </ng-template>
                <ul>
                    <div *ngFor="let item of welcomeInfo.NewFeatures" class="mb-2">
                        <li>{{item.Description}}</li>
                    </div>
                </ul>
            </p-card>
        </div>
        <div *ngIf="welcomeInfo.OutageNotifications && welcomeInfo.OutageNotifications.length > 0">
            <p-card styleClass="border-1 border-primary border-round-lg shadow-none">
                <ng-template pTemplate="title">
                    <div class="p-card-title text-primary">Outage Notifications</div>
                </ng-template>
                <ul>
                    <div *ngFor="let item of welcomeInfo.OutageNotifications" class="mb-2">
                        <li>{{item.Description}}</li>
                    </div>
                </ul>
            </p-card>
        </div>
    </div>
</div>