import { EncryptDecryptComponent } from './common/components/encrypt-decrypt/encrypt-decrypt.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './features/home/home.component'
import { LoginComponent } from './features/identity/login/login.component'
import { deviceResolver } from './common/resolvers/device.resolver'
import { ForgotPasswordComponent } from './features/identity/forgot-password/forgot-password.component'
import { TemporaryPasscodeComponent } from './features/identity/forgot-password/temporary-passcode/temporary-passcode.component'
import { CreateNewPasswordComponent } from './features/identity/forgot-password/create-new-password/create-new-password.component'
import { MfaCollectionComponent } from './features/identity/mfa/mfa-collection/mfa-collection.component'
import { MfaSelectionComponent } from './features/identity/mfa/mfa-selection/mfa-selection.component'
import { MfaVerificationComponent } from './features/identity/mfa/mfa-verification/mfa-verification.component'
import { SecurityQaComponent } from './features/identity/security-qa/security-qa.component'
import { WelcomeComponent } from './features/identity/welcome/welcome.component'
import { MyProfileComponent } from './features/identity/my-profile/my-profile.component'
import { UserCheckinComponent } from './features/identity/user-checkin/user-checkin.component'
import { RoleAuthGuard, RoleAuthGuardActivateChild } from './common/guards/roles.guard'
import { BroadbandFallbackComponent } from './features/orders/broadband-fallback/broadband-fallback.component'

const resetForgotPasswordChildren = [{
  path: '', component: ForgotPasswordComponent
}, {
  path: 'passcode', component: TemporaryPasscodeComponent
}, {
  path: 'create-new', component: CreateNewPasswordComponent,
  data: {
    skipReuse: true
  }
}]

const userChildren = [
  {
    path: 'profileSetting', component: MyProfileComponent
  },
  {
    path: 'timeentry', component: UserCheckinComponent
  }
]

const addGlobalResolver = (routes: Routes) => {
  return routes.map(route => {
    if (route.resolve === undefined) {
      route.resolve = {}
    }
    route.runGuardsAndResolvers = 'always'
    route.resolve['device'] = deviceResolver
    return route
  })
}

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: '', component: LoginComponent },
  {
    path: 'forgot-password',
    children: resetForgotPasswordChildren,
    data: { label: 'Forgot Password' }
  },
  {
    path: 'reset-password',
    children: resetForgotPasswordChildren,
    data: { label: 'Reset Password', forceReset: true }
  },
  {
    path: 'mfa',
    children: [{
      path: 'collection',
      children: [
        {
          path: '', pathMatch: 'full', redirectTo: ':deviceType'
        },
        {
          path: ':deviceType', component: MfaCollectionComponent,
          data: {
            skipReuse: true
          }
        }]
    },
    {
      path: 'verification',
      children: [{ path: '', pathMatch: 'full', redirectTo: ':deviceType' }, {
        path: ':deviceType', component: MfaVerificationComponent,
      }]
    },
    {
      path: 'selection', component: MfaSelectionComponent
    }]
  },
  { path: 'security-qa', component: SecurityQaComponent, data: { skipReuse: true } },
  { path: 'edc', component: EncryptDecryptComponent },
  { path: 'order', loadChildren: () => import('./features/orders/orders.module').then(m => m.OrdersModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  { path: 'support', loadChildren: () => import('./features/support/support.module').then(m => m.SupportModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  { path: 'schedule', loadChildren: () => import('./features/schedule/schedule.module').then(m => m.ScheduleModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  { path: 'admin', loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  { path: 'analytics', loadChildren: () => import('./features/analytics/analytics.module').then(m => m.AnalyticsModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  { path: 'inventory', loadChildren: () => import('./features/inventory/inventory.module').then(m => m.InventoryModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  { path: 'financial', loadChildren: () => import('./features/financial/financial.module').then(m => m.FinancialModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  { path: 'customers', loadChildren: () => import('./features/customers/customers.module').then(m => m.CustomersModule), canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild] },
  {
    path: 'user',
    children: userChildren,
    data: { label: 'User' }, canActivate: [RoleAuthGuard], canActivateChild: [RoleAuthGuardActivateChild]
  },
  { path: 'broadband-fallback', component: BroadbandFallbackComponent},
]

@NgModule({
  imports: [RouterModule.forRoot(addGlobalResolver(routes), { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
