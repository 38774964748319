import { Component, OnInit, inject } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { UserClockInResponse } from '../../../common/models/UserId';
import { finalize, take } from 'rxjs';
import { BaseComponent } from '../../../common/components/base.component';
import { SpinnerService } from '../../../common/services/spinner.service';

@Component({
  selector: 'app-user-checkin',
  templateUrl: './user-checkin.component.html',
  styleUrl: './user-checkin.component.scss'
})
export class UserCheckinComponent extends BaseComponent implements OnInit {

  #userService = inject(UserService)
  #spinnerService = inject(SpinnerService)
  today = new Date();
  isCheckedIn = true;
  clockInTimes: UserClockInResponse[] = [];

  ngOnInit(): void {
    this.getClockinTimes();
  }
  getClockinTimes() {
    this.#spinnerService.setLoading(true, 'user-clockinTimes');
    this.#userService.clockinTimes()
      .pipe(
        take(1),
        finalize(() => { this.#spinnerService.setLoading(false, 'user-clockinTimes'); }))
      .subscribe({
        next: (val) => {
          this.clockInTimes = val;
          this.isCheckedIn = (this.clockInTimes.length > 0 && this.clockInTimes[this.clockInTimes.length - 1].ClockOut == undefined);
        },
        error: () => {
          this.isCheckedIn = false;
        }
      })
  }

  onCheckinClicked() {
    if (this.isCheckedIn) {
      this.#spinnerService.setLoading(true, 'user-checkout');
      this.#userService.checkout()
        .pipe(
          take(1),
          finalize(() => { this.#spinnerService.setLoading(false, 'user-checkout'); }))
        .subscribe({
          next: () => {
            this.getClockinTimes();
          }
        });
    } else {
      this.#spinnerService.setLoading(true, 'user-checkin');
      this.#userService.checkin()
        .pipe(
          take(1),
          finalize(() => { this.#spinnerService.setLoading(false, 'user-checkin'); }))
        .subscribe({
          next: () => {
            this.getClockinTimes();
          }
        });
    }
  }

  onCancelClicked() {

  }
}
