import { Component, effect, inject } from '@angular/core';
import { UserStateService } from '../../../common/store/state/user/user.state.service';
import { BaseComponent } from '../../../common/components/base.component';
import { UserDetails, UserWelcomeResponse } from '../../../common/models/Login';
import { OrderStateService } from '../../../common/store/state/order/order.state.service';
import { GenericStateService } from '../../../common/store/generic-state/generic.state.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent extends BaseComponent {
  orderEntryButtonEnabled = false;
  #userStateService = inject(UserStateService)
  #orderStateService = inject(OrderStateService)
  #genericStateService = inject(GenericStateService)
  welcomeInfo: UserWelcomeResponse;
  user: UserDetails;

  constructor() {
    effect(() => {
      this.welcomeInfo = this.#userStateService.getUserWelcomeInfo();
      this.user = this.#userStateService.getUser();

      if (this.welcomeInfo) {
        if (this.welcomeInfo.Modules && this.welcomeInfo.Modules.find(x => x.toLocaleLowerCase() == 'orderentry')) {
          this.orderEntryButtonEnabled = true;
        }
      }
    })
    super();
  }

  onStartNewOrderClicked() {
    this.#genericStateService.removeAllItems()
    this.#orderStateService.setForms({})
    this.router.navigate(['order/setup'])
  }
}
