<div>
    <app-authorized-header [header]="'User Profile'" [subHeader]="'You can edit your account information below.'"
        [subHeaderCustomClass]="'text-xl'"></app-authorized-header>
    <div class="mb-3">
        <p-button aria-label="Youtube" styleClass="outline-none px-0 text-2xl" [link]="true"
            (click)="onLockButtonClicked()">
            <i class="pi {{lockButton.icon}} text-2xl pr-2"></i>
            <span class="text-2xl">{{lockButton.label}}</span>
        </p-button>
    </div>
    <p-card role="user-profile" styleClass="shadow-none border-1 border-round-2xl border-400">
        <sp-form-wrapper [formGroup]="formGroup">
            <div class="flex flex-column pl-4 card-padding-right" [ngClass]="{'locked' : lockButton.isLocked}">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid">
                            <div class="col-12">
                                <div class="grid">
                                    <div class="col pr-7">
                                        <div class="field">
                                            <label htmlFor="firstName">First Name</label>
                                            <input type="text" placeholder="First Name" pInputText
                                                formControlName="firstName" class="w-full skinny" />
                                            <sp-control-error [parameters]="['First Name']"
                                                [control]="formGroup.controls['firstName']"></sp-control-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="field">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input type="text" placeholder="Last Name" pInputText
                                                formControlName="lastName" class="w-full skinny" />
                                            <sp-control-error [parameters]="['Last Name']"
                                                [control]="formGroup.controls['lastName']"></sp-control-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field">
                                    <label htmlFor="securityQuestion">Security Question</label>
                                    <div>
                                        <p-dropdown styleClass="w-25rem" formControlName="securityQuestion"
                                            [options]="lstQuestions$ | async" optionLabel="Question"
                                            optionValue="QuestionId" placeholder="Please select your question"
                                            [styleClass]="'w-full skinny-dropdown'" />
                                        <div *ngIf="!lockButton.isLocked" class="mt-1">
                                            <input type="text" placeholder="Security Answer" pInputText
                                                formControlName="securityAnswer" class="w-full mb-1 skinny" />
                                            <sp-control-error [parameters]="['Security Answer']"
                                                [control]="formGroup.controls['securityAnswer']"></sp-control-error>
                                        </div>
                                    </div>
                                    <sp-control-error [parameters]="['Question']"
                                        [control]="formGroup.controls['securityQuestion']"></sp-control-error>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="flex justify-content-end">
                                    <button label="SAVE CHANGES" class="p-button-rounded shadow-4" rounded="true"
                                        pButton pRipple (click)="saveBasicInformation()" size="small"
                                        [disabled]="lockButton.isLocked">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <hr>
            </div>
            <div class="flex flex-column pl-4 card-padding-right py-2" [ngClass]="{'locked' : lockButton.isLocked}">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid">
                            <div class="col-12">
                                <div class="flex justify-content-between">
                                    <div>
                                        <label htmlFor="contactEmail">Contact Email</label>
                                        <div>
                                            <span>
                                                {{userDetails.ContactEmail}}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <p-button [outlined]="true" styleClass="shadow-4" label="EDIT" [rounded]="true"
                                            (click)="changeEmail()" [disabled]="lockButton.isLocked">
                                        </p-button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="flex justify-content-between">
                                    <div>
                                        <label htmlFor="phone">Phone</label>
                                        <div>
                                            <span>
                                                {{userDetails.MobilePhone | phone}}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <p-button [outlined]="true" label="EDIT" styleClass="shadow-4" [rounded]="true"
                                            (click)="changePhone()" [disabled]="lockButton.isLocked">
                                        </p-button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="flex justify-content-between">
                                    <div>
                                        <label htmlFor="password">Password</label>
                                        <div>
                                            <span>
                                                ******************
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <p-button [outlined]="true" label="EDIT" styleClass="shadow-4" [rounded]="true"
                                            (click)="changePassword()" [disabled]="lockButton.isLocked">
                                        </p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </sp-form-wrapper>
    </p-card>
</div>

<p-dialog [(visible)]="changeEmailDialog" [modal]="true" [breakpoints]="{ '1120px': '80vw','575px': '90vw' }">
    <div class="px-4 pb-2">
        <sp-form-wrapper [formGroup]="emailFormGroup">
            <h1 class="text-center mb-2">Change your email address</h1>
            <div class="font-semibold mb-3 mt-3 text-xl">
                <span>Current email address: {{userDetails.ContactEmail}}</span>
            </div>
            <div class="mb-3 text-xl text-700">
                <span>Enter the new email address for your account below. We will send a one-time password (OTP) to that
                    address.
                </span>
            </div>
            <div class="grid">
                <div class="col-12">
                    <div class="grid">
                        <div class="col-12">
                            <div class="field mb-0">
                                <span htmlFor="email" class="text-xl">New email address</span>
                                <input type="text" placeholder="Email" pInputText formControlName="email"
                                    class="w-full skinny mt-1" />
                                <sp-control-error [parameters]="['Email']"
                                    [control]="emailFormGroup.controls['email']"></sp-control-error>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="flex justify-content-center">
                                <button label="CONTINUE" class="p-button-rounded" pButton pRipple
                                    (click)="onChangeEmailOrPhoneContinue()" size="small">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </sp-form-wrapper>
    </div>
</p-dialog>

<p-dialog [(visible)]="changePhoneDialog" [modal]="true" [breakpoints]="{ '1120px': '80vw','575px': '90vw' }">
    <div class="px-4 pb-2">
        <sp-form-wrapper [formGroup]="phoneFormGroup">
            <h1 class="text-center mb-2">Change your Phone Number</h1>
            <div class="font-semibold mb-3 mt-3 text-xl">
                <span>Current phone number: {{userDetails.MobilePhone | phone}}</span>
            </div>
            <div class="mb-3 text-xl text-700">
                <span>Enter the new phone number for your account below. We will send a one-time password (OTP) to that
                    number.
                </span>
            </div>
            <div class="grid">
                <div class="col-12">
                    <div class="grid">
                        <div class="col-12">
                            <div class="field mb-0">
                                <span htmlFor="newPhone" class="text-xl">New phone number</span>
                                <p-inputMask [autoClear]="false" [autofocus]="true" mask="999-999-9999"
                                    formControlName="mobilePhone" placeholder="999-999-9999"
                                    styleClass="w-full mb-1 mt-1"></p-inputMask>
                                <sp-control-error [parameters]="['Mobile Phone']"
                                    [control]="phoneFormGroup.controls['mobilePhone']"></sp-control-error>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="flex justify-content-center">
                                <button label="CONTINUE" pButton pRipple class="p-button-rounded"
                                    (click)="onChangeEmailOrPhoneContinue()" size="small">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </sp-form-wrapper>
    </div>
</p-dialog>


<p-dialog [(visible)]="contactValidateDialog" [modal]="true" [breakpoints]="{ '1120px': '80vw','575px': '90vw' }">
    <div class="px-4 pb-2 text-center">
        <div>
            <h1 class="text-center mb-2">Two-Factor Authentication</h1>
            <div class="mb-3 text-xl text-700 text-center">
                {{verificationHeader}}
            </div>
            <div class="mb-2">
                <span>The verification code is valid for 10 minutes
                </span>
            </div>
        </div>
        <div>
            <sp-form-wrapper [formGroup]="pinForm" (submit)="onContactValidate()">
                <div class="flex flex-column align-items-center">
                    <div>
                        <div class="mb-3">
                            <p-inputOtp [autofocus]="true" formControlName="pin" [tabindex]="1" [integerOnly]="true"
                                [length]="6" class="w-full mb-1 justify-content-between"></p-inputOtp>
                            <sp-control-error [parameters]="['Code']"
                                [control]="pinForm.controls['pin']"></sp-control-error>
                        </div>
                        <div>
                            <button label="VALIDATE" pButton pRipple type="submit" [disabled]="!pinForm.valid"
                                size="small">
                            </button>
                        </div>
                    </div>
                </div>
            </sp-form-wrapper>
        </div>
        <div class="flex flex-column align-items-center w-full my-2">
            <p-button label="Send another code" icon="pi pi-refresh" iconPos="right" [link]="true"
                styleClass="outline-none text-sm" (click)="resendPin()"></p-button>
        </div>
    </div>

</p-dialog>


<p-dialog [(visible)]="changePasswordDialog" [modal]="true" [breakpoints]="{ '1120px': '80vw','575px': '90vw' }">
    <div class="px-4 pb-2">
        <sp-form-wrapper [formGroup]="passwordForm">
            <h1 class="text-center mb-2">Change Password</h1>
            <div class="grid mt-5">
                <div class="col-12">
                    <div class="flex flex-column">
                        <span class="mb-3">
                            <span htmlFor="currentPassword" class="text-xl">Current Password</span>
                            <p-password placeholder="Enter your current password" formControlName="currentpass"
                                [toggleMask]="true" [feedback]="false" styleClass="w-full" inputStyleClass="w-full my-1"></p-password>
                            <sp-control-error [parameters]="['Password']"
                                [control]="passwordForm.controls['currentpass']"></sp-control-error>
                        </span>
                        <span class="mb-3">
                            <span htmlFor="newPassword" class="text-xl">New Password</span>
                            <p-password placeholder="Enter your new password" formControlName="pass" [toggleMask]="true"
                                styleClass="w-full" inputStyleClass="w-full my-1"></p-password>
                            <sp-control-error [parameters]="['Password']"
                                [control]="passwordForm.controls['pass']"></sp-control-error>
                        </span>
                        <span class="mb-4">
                            <span htmlFor="confirmNewPassword" class="text-xl">Confirm New Password</span>
                            <p-password placeholder="Confirm your new password" formControlName="repass"
                                [feedback]="false" [toggleMask]="true" styleClass="w-full my-1"
                                inputStyleClass="w-full mb-1"></p-password>
                            <sp-control-error [parameters]="['Password']"
                                [control]="passwordForm.controls['repass']"></sp-control-error>
                        </span>
                        <p class="mt-0 ml-2 p-error" *ngIf="passwordForm?.errors">Passwords must match</p>
                        <div class="flex justify-content-between mb-4">
                            <div class="text-sm">
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('^.{8,32}$'), label: 'must be 8-32 characters' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(?=.*[A-Z])'), label: '1 uppercase letter' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(?=.*[a-z])'), label: '1 lowercase letter' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(.*[0-9].*)'), label: '1 number' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(?=.*[!#$%&()*+,-.:;<=>?@[\\\]^_{}~])'), label: '1 special character' }"></ng-container>
                                <ng-container 
                                    *ngTemplateOutlet="charChecker; context : { matched: !passValue?.match('([\\/|\'&quot;\\\\])'), label: 'No invalid characters (&quot; \/ | \\ \')' }"></ng-container>
                            </div>
                        </div>
                        <div class="justify-content-center flex mb-3">
                            <button label="SAVE CHANGES" pButton pRipple (click)="savePassword()"
                                [disabled]="!passwordForm.valid || !isValidCharacter(passValue)" size="small" class="p-button-rounded">
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </sp-form-wrapper>
    </div>
</p-dialog>


<ng-template #charChecker let-matched="matched" let-label="label">
    <span class="block">
        @if (matched) {
        <i class="pi pi-check" [style.color]="'green'"></i>
        } @else {
        <i class="pi pi-times-circle" [style.color]="'red'"></i>
        }
        {{label}}
    </span>
</ng-template>