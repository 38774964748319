import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-broadband-fallback',
  templateUrl: './broadband-fallback.component.html',
  styleUrl: './broadband-fallback.component.scss'
})
export class BroadbandFallbackComponent {

}
