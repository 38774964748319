<app-unauthorized-header-layout header="Sign In" [headerCustomClass]="'login-header'"></app-unauthorized-header-layout>

@if (invalidLogin) {
<div class="flex p-error justify-content-center mb-5 text-2xl">{{invalidLogin}}</div>
}
<div class="flex justify-content-center flex-wrap  w-full">
    <div class="surface-card px-4 pb-4 w-saraplus-form border-round w-full sm:w-7 md:w-6 lg:4">
        <div>
            <sp-form-wrapper [formGroup]="loginForm" (submit)="login()">
                <div class="flex flex-column">
                    <span class="mb-3">
                        <input type="text" placeholder="Enter email" pInputText formControlName="email"
                            class="w-full mb-1" />
                        <sp-control-error [parameters]="['Email']"
                            [control]="loginForm.controls['email']"></sp-control-error>
                    </span>
                    <span class="mb-4">
                        <p-password placeholder="Enter password" formControlName="pass" [toggleMask]="true"
                            styleClass="w-full" inputStyleClass="w-full mb-1" [feedback]="false"></p-password>
                        <sp-control-error [parameters]="['Password']"
                            [control]="loginForm.controls['pass']"></sp-control-error>
                    </span>
                    <div class="flex justify-content-between mb-5">
                        <p-button label="Forgot Password?" [link]="true"
                            styleClass="underline outline-none font-italic text-lg -ml-3"
                            [routerLink]="'/forgot-password'"></p-button>
                        <button label="LOG IN" pButton pRipple type="submit" [disabled]="!loginForm.valid" size="small">
                        </button>
                    </div>
                </div>
            </sp-form-wrapper>
        </div>
    </div>
</div>

<div class="location-enable-container">
    <p-dialog header="Please enable your location to proceed."  [(visible)]="chromeLocationDisabled" [modal]="true" [position]="'center'" [style]="{width: '52vw', 'max-height': '90vh'}"
        [draggable]="false" [resizable]="false">
        <p class="font-bold">
            Enabling Location Services on Google Chrome (Desktop):
        </p>
        <ol>
            <li>Open Google Chrome on your computer or mobile device.</li>
            <li>Click the three vertical dots in the top-right corner to open the menu.</li>
            <li>Select “Settings” from the dropdown menu.</li>
            <li>Scroll down and click “Privacy and Security” to access more settings.</li>
            <li>In the “Privacy and security” section, click on “Site settings”.</li>
            <li>Scroll down and select “Location”.</li>
            <li>Toggle the switch to enable location permissions for websites.</li>
            <li>To manage permissions for specific websites, use the “Add” button next to the “Block” or “Allow” lists.</li>
            <li>To completely disable location permissions, toggle the main switch to off.</li>
        </ol>
        <p class="font-bold pt-3">
            Enabling Location Services on Google Chrome (IOS):
        </p>
        <ng-container *ngTemplateOutlet="iosDefaultTemplate">
        </ng-container>
        <div class="flex justify-content-end mt-4">
            <button label="OKAY" pButton pRipple (click)="chromeLocationDisabled = false"  size="small">
            </button>
        </div>
    </p-dialog>

    <p-dialog header="Please enable your location to proceed."  [(visible)]="firefoxLocationDisabled" [modal]="true" [position]="'center'" [style]="{width: '52vw', 'max-height': '90vh'}"
    [draggable]="false" [resizable]="false">
        <p class="font-bold">
            Enabling Location Services on Mozilla Firefox (Desktop):
        </p>
        <ol>
            <li>Open Mozilla Firefox on your computer or mobile device.</li>
            <li>Click the three horizontal lines in the top-right corner to open the menu.</li>
            <li>Choose “Settings” from the dropdown menu.</li>
            <li>In the left-hand menu, select “Privacy & Security.”</li>
            <li>Scroll to the “Permissions” section and click on “Settings” next to “Location.”</li>
            <li>Check the option “Allow websites to ask for your physical location.”</li>
            <li>You can also manage exceptions by clicking on the “Exceptions…” button.</li>
        </ol>
        <p class="font-bold pt-3">
            Enabling Location Services on Mozilla Firefox (IOS):
        </p>
        <ng-container *ngTemplateOutlet="iosDefaultTemplate">
        </ng-container>
        <div class="flex justify-content-end mt-4">
            <button label="OKAY" pButton pRipple (click)="firefoxLocationDisabled = false"  size="small">
            </button>
        </div>
    </p-dialog>


    <p-dialog header="Please enable your location to proceed."  [(visible)]="safariLocationDisabled" [modal]="true" [position]="'center'" [style]="{width: '52vw', 'max-height': '90vh'}"
    [draggable]="false" [resizable]="false">
        <p class="font-bold">
            Enabling Location Services on Safari (Desktop):
        </p>
        <ol>
            <li>Open Safari on your Mac</li>
            <li>Click on “Safari” in the top menu bar and select “Settings.”</li>
            <li>In the Preferences window, go to the “Websites” tab.</li>
            <li>In the left-hand sidebar, select “Location.”</li>
            <li>Ensure the checkbox next to “Allow websites to request access to your location” is checked.</li>
            <li>Decide whether to allow all websites or only specific ones to access your location.</li>
            <li>Manage individual website settings by selecting them from the list and choosing “Deny,” “Prompt,” or “Allow.”</li>
        </ol>
        <p class="font-bold pt-3">
            Enabling Location Services on Safari (IOS):
        </p>
        <ng-container *ngTemplateOutlet="iosDefaultTemplate">
        </ng-container>
        <div class="flex justify-content-end mt-4">
            <button label="OKAY" pButton pRipple (click)="safariLocationDisabled = false"  size="small">
            </button>
        </div>
    </p-dialog>


    <p-dialog header="Please enable your location to proceed."  [(visible)]="edgeLocationDisabled" [modal]="true" [position]="'center'" [style]="{width: '52vw', 'max-height': '90vh'}"
    [draggable]="false" [resizable]="false">
        <p class="font-bold">
            Enabling Location Services Microsoft Edge (Desktop):
        </p>
        <ol>
            <li>Open Microsoft Edge.</li>
            <li>Click the three horizontal dots in the top-right corner to open a menu.</li>
            <li>Click on “Settings” from the menu.</li>
            <li>Click on “Cookies and site permissions" in the left-hand sidebar.</li>
            <li>Scroll down to the "All permissions" section.</li>
            <li>Click "Location" to open location settings.</li>
            <li>You will see a toggle switch next to "Ask before accessing (recommended)" - Make sure the toggle is in the ON position</li>
            <li>If you see portal.saraplus.com in the "Block" list, remove it by clicking the icon that looks like a trash can.</li>
            <li>Close Microsoft Edge.</li>
        </ol>
        <p class="font-bold pt-3">
            Enabling Location Services on Microsoft Edge (IOS):
        </p>
        <ng-container *ngTemplateOutlet="iosDefaultTemplate">
        </ng-container>
        <div class="flex justify-content-end mt-4">
            <button label="OKAY" pButton pRipple (click)="edgeLocationDisabled = false"  size="small">
            </button>
        </div>
    </p-dialog>
    
</div>

<ng-template #iosDefaultTemplate>
    <ol>
        <li>
            <p>To turn on Location Services on an iOS device, you can do the following:</p>
            <ol type="a">
                <li>Open Settings</li>
                <li>Select Privacy & Security</li>
                <li>Select Location Services </li>
                <li>Toggle Location Services to On</li>
            </ol>
        </li>
        <li>
            <p>You can also control location permissions for specific apps:</p>
            <ol type="a">
                <li>Go to Settings > Privacy & Security > Location Services</li>
                <li>Ensure Location Services is on</li>
                <li>Scroll down to find the app</li>
                <li>Tap the app and select an option</li>
            </ol>
        </li>
    </ol>
</ng-template>