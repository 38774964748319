import { Injectable, inject } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { AppStateService } from '../store/state/app/app.state.service'
import { EnvironmentService } from '../services/environment.service'
import { skipInterceptionUrls } from '../constants/skip-url'


@Injectable({
  providedIn: 'root'
})
export class AuthHeaderInterceptor implements HttpInterceptor {


  #appStateService: AppStateService = inject(AppStateService)
  #environmentService: EnvironmentService = inject(EnvironmentService)

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.#addAuthHeader(request))
  }

  #addAuthHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (skipInterceptionUrls.some(item => request.url.includes(item))) {
      return request
    }
    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    })
    const accessToken = this.#appStateService.getToken()?.access_token
    const ignoreEncryption = this.#environmentService.getIgnoreEncryption()
    if (ignoreEncryption) {
      request = request.clone({ headers: request.headers.set('ignoreEncryption', 'true') })
    }

    if (accessToken && request.url.indexOf('oauth/token') < 0) {
      return request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken}`) })
    }


    return request
  }

}